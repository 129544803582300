import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext, Heading } from "Components";

export const VideoMeta = ({ heading, body }) => {
  const { event } = useContext(EventContext);
  return (
    <VideoMetaWrapper>
      <Heading heading={heading} />
      <div>{body}</div>
    </VideoMetaWrapper>
  );
};

const VideoMetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
