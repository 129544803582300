import React, { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, Header } from "Components";
import { Layout } from "../Layout";
import { fonts } from "theme/fonts";
import { AgeGateForm } from "./AgeGateForm";

export const AgeGate = ({ handleAgeGateSubmit }) => {
  const { event } = useContext(EventContext);

  const {
    background,
    banner,
    body,
    btnText,
    fields,
    footer,
    heading,
  } = event.ageGate;

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const siteBgDesktop = `${api}/o/${storageDirectory}%2F${background.desktop}?alt=media`;
  const siteBgMobile = `${api}/o/${storageDirectory}%2F${background.mobile}?alt=media`;
  const bannerOutline = `${api}/o/${storageDirectory}%2F${banner}?alt=media`;

  return (
    <Layout flexGrow>
      <Background background={siteBgDesktop}>
        <Banner>
          <img src={bannerOutline} />
        </Banner>

        <Header showHeader />

        <PageContent>
          <Content>
            <h2>{ReactHtmlParser(heading)}</h2>
            <Body>{ReactHtmlParser(body)}</Body>
            <FormContainer>
              <AgeGateForm handleAgeGateSubmit={handleAgeGateSubmit} />
            </FormContainer>
          </Content>

          <AgeGateFooter>{ReactHtmlParser(footer)}</AgeGateFooter>
        </PageContent>
      </Background>
    </Layout>
  );
};

const textShadow = `0 3px 6px rgba(0,0,0,.16);`;

const Banner = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  justify-content: center;
  top: -125px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: flex;
  }

  img {
    display: block;
    width: 33%;
    max-width: 135px;
  }
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;

  ${(props) =>
    props.background &&
    `
    background-image: url(${props.background});
    background-size: cover;
    background-position: center;
  `}
`;

const PageContent = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 25px 15px 25px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding-top: 100px;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: clamp(46px, 12vw, 64px);
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
    text-shadow: ${textShadow};
    text-align: center;
  }
`;

const Body = styled.p`
  width: 100%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  text-align: center;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
`;

const AgeGateFooter = styled.div`
  text-align: center;

  h4 {
    font-size: 32px;
    margin-bottom: 16px;
    text-shadow: ${textShadow};
    line-height: 1;
  }

  p {
    margin-top: 0;
  }

  a {
    color: #ffffff;
  }
`;
