import React from "react";
import styled from "styled-components";
import { fonts } from "theme/fonts";

export const CountdownSmall = ({ label, days, hours, minutes, seconds }) => {
  return (
    <CountdownContainer>
      <CountdownTitle>{label}</CountdownTitle>
      <GridRow>
        {days >= 1 && (
          <Outline>
            <Increment>{days}</Increment>
            <Label>Day{days !== 1 ? "s" : ""}</Label>
          </Outline>
        )}
        <Outline>
          <Increment>{hours}</Increment>
          <Label>Hour{hours !== 1 ? "s" : ""}</Label>
        </Outline>
        <Outline>
          <Increment>{minutes}</Increment>
          <Label>Minute{minutes !== 1 ? "s" : ""}</Label>
        </Outline>
        {days < 1 && (
          <Outline>
            <Increment>{seconds}</Increment>
            <Label>Second{seconds !== 1 ? "s" : ""}</Label>
          </Outline>
        )}
      </GridRow>
    </CountdownContainer>
  );
};

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CountdownTitle = styled.h3`
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 12px;
  color: #ffffff;
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const Outline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  background-color: #ffffff;
  border-radius: 7px;
  padding: 0px 10px 10px;
`;

const Increment = styled.div`
  font-weight: 700;
  font-size: clamp(62px, 13vw, 72px);
  line-height: 1;
  color: #fec82e;
  text-align: center;
  font-family: ${fonts.header};
`;

const Label = styled.div`
  font-size: clamp(12px, 3vw, 18px);
  text-transform: uppercase;
  color: #fec82e;
`;
