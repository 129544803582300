import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "Components";
import { MaxWidthContainer } from "Routes/Root/Components/Layout";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);

export const Header = ({ showHeader, registration, preCampaign }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { brand } = event.logos;

  let eventLogoImg = `${api}/o/${storageDirectory}%2F${brand.path}?alt=media`;

  const logoClickHandler = (link) => {
    logClick({
      label: "Header Logo",
      url: link,
    });
  };

  return (
    <>
      {showHeader && (
        <HeaderWrapper as="header" registration={registration}>
          <HeaderContainer>
            <PrimaryLogo src={eventLogoImg} />
          </HeaderContainer>
        </HeaderWrapper>
      )}
    </>
  );
};

const HeaderWrapper = styled.header`
  z-index: 1;
  display: flex;
  flex-direction: row;
`;

const HeaderContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 15px;

  @media ${(props) => props.theme.maxMedia.small} {
    padding: 25px;
    justify-content: start;
  }
`;

const Link = styled.a`
  width: 30%;
  max-width: 151px;

  @media ${(props) => props.theme.maxMedia.small} {
    width: 50%;
  }
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
`;

const PrimaryLogo = styled(Logo)`
  max-width: 300px;

  @media ${(props) => props.theme.maxMedia.small} {
    width: 50%;
  }
`;

const SecondaryLogo = styled(Logo)`
  width: 50%;
  max-width: 249px;
`;
