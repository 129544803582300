import React, { useContext } from "react";
import styled from "styled-components";
import { Header, Main, Footer, EventContext } from "Components";

export const Layout = ({
  registration,
  preCampaign,
  registered,
  // header
  showHeader,
  // main
  heroContent,
  heading,
  subHeading,
  body,
  children,
  flexGrow,
  // site footer
  showFooter,
}) => {
  const { event } = useContext(EventContext);

  return (
    <SiteContainer>
      {event.test && <TestEventBanner>Viewing Test Event</TestEventBanner>}

      <Header
        showHeader={showHeader}
        registration={registration}
        preCampaign={preCampaign}
      />

      <Main
        heroContent={heroContent}
        heading={heading}
        subHeading={subHeading}
        body={body}
        children={children}
        flexGrow={flexGrow}
        registration={registration}
        preCampaign={preCampaign}
      />

      <Footer
        showFooter={showFooter}
        registration={registration}
        preCampaign={preCampaign}
        registered={registered}
      />
    </SiteContainer>
  );
};

// ======== SITE CONTAINER ======== //

const SiteContainer = styled.div`
  flex-direction: column;
  margin: 0 auto;
  max-width: 2000px;
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;

  @media ${(props) => props.theme.maxMedia.large} {
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.65);
  }
`;

const TestEventBanner = styled.div`
  background-color: #ff003b;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding: 3px 10px;
`;

// ======== MAX WIDTH CONTAINER ======== //

export const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

// ======== HERO TEMPLATE ======== //

export const HeroWrapper = styled.div`
  color: #ffffff;
`;

export const HeroContainer = styled(MaxWidthContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px 15px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 15px 25px 50px;
  }
`;

export const HeroBackground = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 7% 15px 9%;
`;
