const brand = {
  regular: "Speedee-Regular",
  bold: "Speedee-Bold",
  condensedRegular: "Speedee-Condensed-Regular",
  condensedBold: "Speedee-Condensed-Bold",
};

const base = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`;
const emoji = "MaxEmoji";

const fallbacks = {
  default: `Roboto, ${base}, ${emoji}`,
  header: `Montserrat, ${base}, ${emoji}`,
};

export const fonts = {
  brand,
  default: `${brand.regular}, ${fallbacks.default}`,
  header: `${brand.condensedBold}, ${fallbacks.header}`,
};
