import React from "react";
import { Modal } from "notes";
import styled from "styled-components";

export const HeroVideoModal = ({ video, open, setModalVisible }) => {
  return (
    <VideoModal size="large" open={open} setOpen={setModalVisible}>
      <VideoModalContainer>
        <Video
          src={video}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </VideoModalContainer>
    </VideoModal>
  );
};

const VideoModal = styled(Modal)`
  justify-content: center;
  min-height: unset;
  position: relative;

  svg {
    top: 10px;
    right: 10px;
  }
`;

const VideoModalContainer = styled.div`
  padding-top: 56.25%;
  position: relative;
`;

const Video = styled.iframe`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  border: 0;
`;
