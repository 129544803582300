import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { Modal } from "notes";
import { Layout } from "../Layout";
import {
  EventContext,
  TimeContext,
  AnalyticsContext,
  BrandAd,
} from "Components";
import { RegistrationContent } from "./RegistrationContent";
import { fonts } from "theme/fonts";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas, fab, far);

export const Register = React.memo(({ onSuccess }) => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const { logClick, logVideoAction } = useContext(AnalyticsContext);
  const [cookies, setCookie] = useCookies();

  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState(null);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { brand } = event.logos;
  const brandLogo = `${api}/o/${storageDirectory}%2F${brand.path}?alt=media`;

  let registrationPart = 0;

  if (event.campaignEnd < time) {
    registrationPart = 2;
  } else if (cookies?.registered) {
    registrationPart = 1;
  }

  const backgroundImage = event.register.artistImg[registrationPart];
  let desktopImage = `${api}/o/${storageDirectory}%2F${backgroundImage?.desktop}?alt=media`;
  let mobileImage = `${api}/o/${storageDirectory}%2F${backgroundImage?.mobile}?alt=media`;

  const { productImg } = event.callToActions.brand;
  let productImage = `${api}/o/${storageDirectory}%2F${productImg}?alt=media`;

  const {
    heading: heroVideoHeading,
    video: heroVideo,
  } = event.callToActions.heroVideo;

  const logoClickHandler = (link) => {
    logClick({
      label: "Brand Logo",
      url: link,
    });
  };

  const setModalVisible = (bool, video) => {
    setVideo(video);
    setOpen(bool);
    logVideoAction({
      bool,
      videoLink: video,
      videoTitle: "Hero Video",
    });
  };

  const handleSuccess = (admin, token) => {
    onSuccess(admin, token);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Layout registration flexGrow showFooter>
      <Registration>
        <ContentWrapper>
          <ContentContainer>
            <LogoLink
              href={brand.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => logoClickHandler(brand.link)}
            >
              <Logo src={brandLogo} />
            </LogoLink>

            <RegistrationContent handleSuccess={handleSuccess} />
          </ContentContainer>

          {registrationPart !== 0 && <BrandAd part={registrationPart} />}
        </ContentWrapper>

        <RightContent>
          <ArtistImage
            as="section"
            id="artist-image"
            desktopImage={desktopImage}
            mobileImage={mobileImage}
            part={registrationPart}
          >
            <HeroVideoCTA onClick={() => setModalVisible(true, heroVideo)}>
              <PlayIcon>
                <FontAwesomeIcon icon={["far", "play-circle"]} />
              </PlayIcon>
              <HeroVideoHeading>
                {ReactHtmlParser(heroVideoHeading)}
              </HeroVideoHeading>
            </HeroVideoCTA>
            {registrationPart === 2 && <ProductImage src={productImage} />}
            <GradientBackground />
          </ArtistImage>

          {registrationPart === 0 && <BrandAd part={0} />}
        </RightContent>
      </Registration>

      <VideoModal size="large" open={open} setOpen={setModalVisible}>
        <VideoModalContainer>
          <Video
            src={video}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </VideoModalContainer>
      </VideoModal>
    </Layout>
  );
});

// ======== CONTENT / LEFT SIDE ======== //

const Registration = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    flex-direction: row;
  }
`;

const ContentWrapper = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.small} {
    width: 100%;
    max-width: 425px;
    display: flex;
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  padding: 20px 15px;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    padding: 50px;
  }
`;

const LogoLink = styled.a`
  width: 100%;
  max-width: 50px;
  margin-bottom: 15px;
  display: inline-block;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    margin-bottom: 25px;
  }
`;

const Logo = styled.img`
  width: 100%;
  display: block;
`;

// ======== ARTIST IMAGE / RIGHT SIDE ======== //

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ArtistImage = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 15px;
  position: relative;

  background-image: url(${(props) => props.mobileImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 135vw;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    background-image: url(${(props) => props.desktopImage});
    flex: 1;
    height: unset;
    padding: 50px;
    ${(props) => props.part === 1 && `background-position: left;`};
  }
`;

const GradientBackground = styled.div`
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(transparent, rgb(70 30 0 / 75%));
`;

const HeroVideoCTA = styled.div`
  display: flex;
  z-index: 1;
  cursor: pointer;
  align-items: center;
`;

const PlayIcon = styled.div`
  font-size: clamp(32px, 16vw, 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    font-size: clamp(32px, 6vw, 64px);
  }

  path {
    fill: #ffffff;
  }
`;

const HeroVideoHeading = styled.h4`
  font-size: clamp(11px, 5vw, 24px);
  line-height: 1;
  margin: 0;
  font-family: ${fonts.brand.condensedRegular};
  color: #ffffff;
  text-transform: uppercase;

  span {
    display: block;
    font-size: clamp(20px, 9vw, 44px);
    font-family: ${fonts.brand.condensedBold};
  }

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    font-size: clamp(11px, 2.5vw, 24px);
    span {
      font-size: clamp(20px, 4vw, 44px);
    }
  }
`;

const ProductImage = styled.img`
  display: block;
  width: 20%;
  max-width: 275px;
  z-index: 1;
  position: absolute;
  right: 15px;
  bottom: 0;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    right: 35px;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    right: 50px;
    width: 25%;
  }
`;

// ======== MODAL ======== //

const VideoModal = styled(Modal)`
  justify-content: center;
  min-height: unset;
  position: relative;
  svg {
    top: 10px;
    right: 10px;
  }
`;

const VideoModalContainer = styled.div`
  padding-top: 56.25%;
  position: relative;
`;

const Video = styled.iframe`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  border: 0;
`;
