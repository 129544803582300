import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "Components";

export const EventSponsors = ({ registered }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const sponsorLogoClickHandler = (link) => {
    logClick({
      label: "Sponsor/Partner Logo",
      url: link,
    });
  };

  const partnerHeading = event.callToActions.partners.heading;
  const sponsorLogos = event.logos.sponsors;

  return (
    <Wrappper>
      <h4>{!registered ? partnerHeading[0] : partnerHeading[1]}</h4>
      <SponsorLogos registered={registered}>
        {sponsorLogos.map((sponsor, idx) => (
          <SponsorLogo registered={registered}>
            <a
              key={idx}
              href={sponsor.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                sponsorLogoClickHandler(
                  registered ? sponsor.giftLink : sponsor.link
                )
              }
            >
              <img
                src={`${api}/o/${storageDirectory}%2F${sponsor.path}?alt=media`}
              />
            </a>
          </SponsorLogo>
        ))}
      </SponsorLogos>
    </Wrappper>
  );
};

const Wrappper = styled.div`
  width: 100%;
  margin-bottom: 12px;

  h4 {
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 18px;
    line-height: 1.25;
  }
`;

const SponsorLogos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;

  ${(props) =>
    props.registered
      ? `
    align-items: stretch;
  `
      : `
    align-items: center;
    max-width: 420px;
  `}
`;

const SponsorLogo = styled.div`
  padding: 1% 3%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: flex;
    width: 100%;
  }

  ${(props) =>
    props.registered
      ? `
    width: 33%;

    @media only screen and ${props.theme.maxMedia.medium} {
      padding: 0% 3%;
      width: 20%;
      border-right: 2px solid #ffffff;
      flex-direction: column;

      &:last-of-type {
        border: 0;
      }
    }
  `
      : `
    &:nth-of-type(1),
    &:nth-of-type(2) {
      width: 50%;
    }

    &:nth-of-type(3),
    &:nth-of-type(5) {
      width: 27%;
    }

    &:nth-of-type(4) {
      width: 22%;
    }
  `}
`;
