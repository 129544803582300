import React, { useContext } from "react";
import styled from "styled-components";
import {
  EventContext,
  Header,
  Video,
  VideoChat,
  Heartbeater,
  BrandAd,
} from "Components";
import { Layout, HeroContainer } from "../Layout";
import { AuthAccess } from "services";

export const Show = ({ token }) => {
  const { event } = useContext(EventContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { backgrounds } = event.register;
  const backgroundImg = `${api}/o/${storageDirectory}%2F${backgrounds[0]}?alt=media`;

  return (
    <>
      <Layout registered flexGrow showFooter>
        <Background background={backgroundImg}>
          <Header showHeader />
          <HeroContainer>
            <VideoChat
              videoId={event?.show?.videoId}
              chatId={event?.show?.chatId}
              videoMeta={false}
            />
          </HeroContainer>
        </Background>
        <BrandAd />
      </Layout>
      <AuthAccess token={token} />
      <Heartbeater />
    </>
  );
};

const Background = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${(props) =>
    props.background &&
    `
    background-image: url(${props.background});
    background-size: cover;
    background-position: center;
  `}
`;
