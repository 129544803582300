import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Heading } from "Components";
import { MaxWidthContainer } from "Routes/Root/Components/Layout";

export const Main = ({
  heroContent,
  heading,
  subHeading,
  body,
  children,
  flexGrow,
  registration,
  preCampaign,
}) => {
  let showMainContainer = false;
  if (subHeading || body) showMainContainer = true;

  return (
    <MainWrapper
      id="main"
      flexGrow={flexGrow}
      registration={registration}
      preCampaign={preCampaign}
    >
      {heroContent}
      <Heading heading={heading} />

      {showMainContainer && (
        <BodyWrapper id="body">
          <MaxWidthContainer>
            {subHeading && (
              <Subheading>{ReactHtmlParser(subHeading)}</Subheading>
            )}
            {body && (
              <Body>
                <p>{ReactHtmlParser(body)}</p>
              </Body>
            )}
          </MaxWidthContainer>
        </BodyWrapper>
      )}

      {!!children && children}
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.flexGrow && `flex: 1;`}
`;

const BodyWrapper = styled.div`
  padding: 35px 15px;
`;

const Subheading = styled.h4`
  font-size: clamp(28px, 6vw, 32px);
  margin: 0 0 16px;
`;

const Body = styled.div`
  font-size: 18px;

  p {
    margin-top: 0;
  }
`;
