import React, { useContext } from "react";
import { Duration } from "luxon";
import styled from "styled-components";
import {
  TimeContext,
  EventContext,
  Header,
  Countdown,
  BrandAd,
} from "Components";
import { Layout, HeroContainer, HeroBackground } from "../Layout";

export const PreShow = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { backgrounds } = event.register;
  const backgroundImg = `${api}/o/${storageDirectory}%2F${backgrounds[0]}?alt=media`;

  const timeToStart =
    time < event?.start &&
    Duration.fromMillis(event?.start - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const timer = {
    label: event?.preShow?.countdownLabel ?? "Show Starts In",
    days: timeToStart.days,
    hours: timeToStart.hours,
    minutes: timeToStart.minutes,
    seconds: timeToStart.seconds,
  };

  return (
    <Layout registered flexGrow showFooter>
      <Background background={backgroundImg}>
        <Header showHeader />
        <HeroContainer>
          <HeroBackground>
            <Countdown {...timer} />
          </HeroBackground>
        </HeroContainer>
      </Background>
      <BrandAd />
    </Layout>
  );
};

const Background = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${(props) =>
    props.background &&
    `
    background-image: url(${props.background});
    background-size: cover;
    background-position: center;
  `}
`;
