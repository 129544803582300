import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Duration } from "luxon";
import {
  EventContext,
  TimeContext,
  CountdownSmall as Countdown,
  AnalyticsContext,
} from "Components";
import { RegistrationForm } from "./RegistrationForm";
import { fonts } from "theme/fonts";

export const RegistrationContent = ({ handleSuccess }) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);
  const [cookies, setCookie] = useCookies();

  const clickHandler = (label, link) => {
    logClick({
      label,
      url: link,
    });
  };

  let RegFormContent;
  let showCountdown = false;
  let brandCTAPart = 0;
  let showBrandCTA = false;

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const countdownHeading = event?.preShow?.countdownLabel;

  const timeToStart =
    time < event.start &&
    Duration.fromMillis(event.start - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const timer = {
    label: countdownHeading ? countdownHeading : "Show Starts In",
    days: timeToStart.days,
    hours: timeToStart.hours,
    minutes: timeToStart.minutes,
    seconds: timeToStart.seconds,
  };

  if (event.campaignEnd < time) {
    // campaign has ended
    RegFormContent = (
      <>
        <Heading>{ReactHtmlParser(event.postCampaign.heading)}</Heading>
        <Body>{ReactHtmlParser(event.postCampaign.body)}</Body>
      </>
    );
  } else {
    const { body, heading, submitBtnText } = event.register.postSweeps[0];
    RegFormContent = (
      <>
        <Heading>{ReactHtmlParser(heading)}</Heading>
        <Body>{ReactHtmlParser(body)}</Body>
        <RegistrationForm
          formPart={0}
          onSuccess={handleSuccess}
          submitButton={submitBtnText}
        />
      </>
    );

    if (cookies?.registered) {
      const { heading, body } = event.register.postSweeps[1];
      const {
        btnLink,
        btnText,
        heading: textReminderHeading,
      } = event.callToActions.textReminder;
      RegFormContent = (
        <>
          <ConfirmationHeading>{ReactHtmlParser(heading)}</ConfirmationHeading>
          <ConfirmationBody>{ReactHtmlParser(body)}</ConfirmationBody>

          <Body>{ReactHtmlParser(textReminderHeading)}</Body>
          <ButtonLink
            href={btnLink}
            target="_blank"
            onClick={() => clickHandler("Text Reminder Button", btnLink)}
          >
            {btnText}
          </ButtonLink>
        </>
      );
    }
  }

  return (
    <ContentContainer>
      {RegFormContent}

      {showCountdown && (
        <SmallCountdown>
          <Countdown {...timer} />
        </SmallCountdown>
      )}
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  p {
    margin-top: 0;
  }
`;

const Heading = styled.h2`
  font-size: 42px;
  line-height: 1.25;
  margin-bottom: 16px;
  color: #461e00;
  text-transform: uppercase;

  span {
    font-family: ${fonts.brand.condensedRegular};
  }
`;

const SubHeading = styled.h3`
  font-size: 34px;
  margin: 0;
`;

const Body = styled.p`
  color: #461e00;
  font-size: 18px;

  span,
  strong {
    font-family: ${fonts.brand.bold};
  }
`;

const ConfirmationHeading = styled(Heading)`
  font-size: clamp(36px, 10vw, 58px);
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const ConfirmationBody = styled(Body)``;

const SmallCountdown = styled.div`
  margin-bottom: 32px;
`;

const ButtonLink = styled.a`
  background-color: #fac42d;
  color: #461e00;
  padding: 10px 35px;
  font-size: 18px;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  width: fit-content;

  &:hover {
    background-color: #ebaa00;
  }
`;
