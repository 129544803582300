import React, { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "Components";
import { Layout } from "../Layout";

export const PreCampaign = () => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { brand } = event.logos;
  const brandLogo = `${api}/o/${storageDirectory}%2F${brand.path}?alt=media`;

  const logoClickHandler = (link) => {
    logClick({
      label: "Brand Logo",
      url: link,
    });
  };

  return (
    <Layout preCampaign flexGrow showFooter>
      <LogoLink
        href={brand.link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => logoClickHandler(brand.link)}
      >
        <Logo src={brandLogo} />
      </LogoLink>

      <PageContent>
        <h2>{ReactHtmlParser(event.preCampaign.heading)}</h2>
      </PageContent>
    </Layout>
  );
};

const LogoLink = styled.a`
  width: 100%;
  max-width: 50px;
  margin: 25px 0 0 15px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: 25px 0 0 25px;
  }
`;

const Logo = styled.img`
  width: 100%;
  display: block;
`;

const PageContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 9vw;
  color: #461e00;

  h2 {
    font-size: clamp(18px, 9vw, 64px);
    line-height: 1;
    text-transform: uppercase;
    margin-top: 0;
  }
`;
