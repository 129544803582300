import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { TimeContext, EventContext, AnalyticsContext } from "Components";
import { MaxWidthContainer } from "Routes/Root/Components/Layout";

export const Footer = ({ showFooter, registration, preCampaign }) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);
  const [cookies, setCookie] = useCookies();

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { brand } = event.logos;
  let brandLogo = `${api}/o/${storageDirectory}%2F${brand.path}?alt=media`;

  const footerMenu = false;
  const showPrimaryLogo = false;

  const logoClickHandler = (link) => {
    logClick({
      label: "Footer Logo",
      url: link,
    });
  };

  const footerMenuClickHandler = (link) => {
    logClick({
      label: "Footer Menu Link",
      url: link,
    });
  };

  return (
    <>
      {showFooter && (
        <FooterWrapper as="footer">
          <FooterContainer>
            {showPrimaryLogo && (
              <Link
                href={brand.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => logoClickHandler(brand.link)}
              >
                <Logo src={brandLogo} />
              </Link>
            )}

            {registration && (
              <SweepsDisclaimer>
                {!cookies?.registered && (
                  <>
                    {event.endSweeps > time &&
                      event.footer.sweepsDisclaimer &&
                      ReactHtmlParser(event.footer.sweepsDisclaimer)}
                    {event.footer.grandPrizeDisclaimer &&
                      ReactHtmlParser(event.footer.grandPrizeDisclaimer)}
                  </>
                )}
              </SweepsDisclaimer>
            )}

            {footerMenu && (
              <FooterMenu>
                {footerMenu.map((item, idx) => (
                  <li key={idx}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => footerMenuClickHandler(item.link)}
                    >
                      {item.text}
                    </a>
                  </li>
                ))}
              </FooterMenu>
            )}

            <MaxPolicy>{ReactHtmlParser(event.footer.maxPolicy)}</MaxPolicy>
          </FooterContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #ffffff;
  background-color: #461e00;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 25px 15px;

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    padding: 25px 50px;
  }
`;

const Link = styled.a``;

const Logo = styled.img`
  width: 100%;
  max-width: 250px;
`;

const SweepsDisclaimer = styled.div`
  a,
  a:visited {
    color: #ffffff;
  }
`;

const MaxPolicy = styled.p`
  a,
  a:visited {
    color: #ffffff;
  }
`;

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    padding: 3px 8px;
  }

  a,
  a:visited {
    color: #ffffff;
  }

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    flex-direction: row;
  }
`;
