import React, { useContext, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { EventContext, TimeContext, firebase } from "Components";
import { Register, AdminControls, PreCampaign } from "./Components";

const adminViews = ["precampaign", "register"];

export const Root = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const params = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies();
  const [adminView, setAdminView] = useState(null);

  const handleRegister = useCallback(
    (isAdmin, token) => {
      setCookie("registered", true, { maxAge: 31622400 });
      setCookie("token", token, { maxAge: 31622400 });
      isAdmin && setCookie("admin", true, { maxAge: 31622400 });
    },
    [setCookie]
  );

  useEffect(() => {
    (async () => {
      if (params.get("token") && !cookies.token) {
        const verify = firebase.functions().httpsCallable("auth-verify");
        try {
          const verified = await verify({
            eventId: process.env.REACT_APP_EVENT_ID,
            token: params.get("token"),
          });
          if (verified?.data?.success) {
            handleRegister(verified?.data?.admin, params.get("token"));
          }
        } catch (error) {
          console.error("token validation failed");
        }
      }
      setLoading(false);
    })();
  }, []);

  if (loading) return null;

  const getAdminView = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const viewHistory = urlParams.get("view");

    switch (viewHistory || adminView) {
      case "precampaign":
        return <PreCampaign />;
      case "register":
        return <Register onSuccess={handleRegister} cookies={cookies} />;
      default:
        return null;
    }
  };

  if (cookies?.admin) {
    return (
      <>
        <AdminControls
          views={adminViews}
          selectedView={adminView}
          setAdminView={setAdminView}
        />
        {getAdminView()}
      </>
    );
  }

  if (event.campaignStart > time) return <PreCampaign />;
  return <Register onSuccess={handleRegister} cookies={cookies} />;
};
