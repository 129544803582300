import React, { useContext, useState, useReducer } from "react";
import styled from "styled-components";
import { useCookies } from "react-cookie";
import { Duration, DateTime } from "luxon";
import { EventContext, TimeContext, Field, handleErrors } from "Components";
import { reducer } from "Modules";
import { fonts } from "theme/fonts";

export const AgeGateForm = ({ handleAgeGateSubmit }) => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const [dob, setDob] = useReducer(reducer, {});
  const [errors, setErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [cookies, setCookie] = useCookies();
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorMessages, setErrorMessages] = useState({});

  const {
    background,
    body,
    btnText,
    errorMessage,
    fields,
    footer,
    heading,
  } = event.ageGate;

  const handleChange = (value, name) => {
    setDob(value);
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = async () => {
    let errorObject = handleErrors(fields, dob, 0);
    const errorMsgs = {};

    if (!errorObject) errorObject = {};

    if (dob.MONTH) {
      const month = parseInt(dob.MONTH);
      if (month <= 0 || month > 12) {
        errorObject.MONTH = true;
        errorMsgs["MONTH"] = "Month must be between 01-12.";
      }

      if (dob.MONTH.length <= 1 || dob.MONTH.length > 2) {
        errorObject.MONTH = true;
        errorMsgs["MONTH"] = "Please use MM format.";
      }

      if (isNaN(dob.MONTH)) {
        errorObject.MONTH = true;
        errorMsgs["MONTH"] = "Please use MM format.";
      }
    }

    if (dob.DAY) {
      const day = parseInt(dob.DAY);
      if (day <= 0 || day > 31) {
        errorObject.DAY = true;
        errorMsgs["DAY"] = "Day must be between 01-31.";
      }

      if (dob.DAY) {
        if (dob.DAY.length <= 1 || dob.DAY.length > 2) {
          errorObject.DAY = true;
          errorMsgs["DAY"] = "Please use DD format.";
        }
      }

      if (isNaN(dob.DAY)) {
        errorObject.DAY = true;
        errorMsgs["DAY"] = "Please use DD format.";
      }
    }

    if (dob.YEAR) {
      const year = parseInt(dob.YEAR);
      if (year <= 0) {
        errorObject.YEAR = true;
        errorMsgs["YEAR"] = "Year must be valid.";
      }

      if (dob.YEAR) {
        if (dob.YEAR.length <= 3 || dob.YEAR.length > 4) {
          errorObject.YEAR = true;
          errorMsgs["YEAR"] = "Please use YYYY format.";
        }
      }

      if (isNaN(dob.YEAR)) {
        errorObject.YEAR = true;
        errorMsgs["YEAR"] = "Please use YYYY format.";
      }
    }

    setErrorMessages(errorMsgs);

    if (!Object.keys(errorObject).length) {
      setSubmitting(true);

      const dateReformat = `${dob.YEAR}-${dob.MONTH}-${dob.DAY}`;
      const d = DateTime.fromISO(dateReformat);
      const diff = d.diffNow("years", {});
      const years = diff.values.years;

      if (years <= -21) {
        handleAgeGateSubmit();
      } else {
        setSubmitting(false);
        setErrorMsg(errorMessage);
      }
    } else {
      setErrors(errorObject);
    }
  };

  return (
    <Form>
      {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
      <Fields>
        {fields &&
          fields
            .sort((a, b) => a.order - b.order)
            .map((field, index) => {
              let span = "100%";
              if (field.span === "half") {
                span = "50%";
              } else if (field.span === "third") {
                span = "32%";
              }

              return (
                <FieldContainer span={span} key={index} order={field.order}>
                  {field.type !== "optIn" && !!field.label && (
                    <Label>{field.label}</Label>
                  )}
                  <Field
                    field={field}
                    fieldCheck={!!field.requires ? dob[field.requires] : true}
                    value={dob[field.name]}
                    onChange={(value) => handleChange(value, field.name)}
                    error={{
                      status: errors[field.name],
                      message: errorMessages[field.name]
                        ? errorMessages[field.name]
                        : "This is a required field and cannot be blank.",
                    }}
                    ageGate={true}
                  />
                </FieldContainer>
              );
            })}
      </Fields>

      {submitting ? (
        <SubmitButton disabled>Loading</SubmitButton>
      ) : (
        <SubmitButton onClick={handleSubmit}>
          {btnText ? btnText : "Enter"}
        </SubmitButton>
      )}
    </Form>
  );
};

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 0;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 ${(props) => props.span && props.span};
  width: ${(props) => props.span && props.span};
  ${(props) => props.order && `order: ${props.order};`};
`;

const Label = styled.label``;

const SubmitButton = styled.button`
  background-color: #fec82e;
  color: #ffffff;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 10px;
  border: 0;
  outline: none;
  letter-spacing: 2px;

  &:hover {
    background-color: #ebaa00;
    cursor: pointer;
  }
`;
