import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "Components";
import { fonts } from "theme/fonts";

export const CTASetTheSet = () => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const { body, button, link, poweredBy } = event.callToActions.setTheSet;
  let poweredByLogo = `${api}/o/${storageDirectory}%2F${poweredBy}?alt=media`;

  const setTheSetHandler = () => {
    logClick({
      label: "Set the Set Song Picker",
      url: link,
    });
  };

  return (
    <SetTheSet>
      <p>{body}</p>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={setTheSetHandler}
      >
        {button}
      </a>
      <img src={poweredByLogo} />
    </SetTheSet>
  );
};

const SetTheSet = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: column;

  p {
  }

  a {
    background-color: #ffffff;
    color: #c8102e;
    padding: 10px;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
  }

  a:hover {
    background-color: #000000;
    color: #ffffff;
  }

  img {
    display: block;
    width: 100%;
    max-width: 75px;
    margin-top: 15px;
  }
`;
