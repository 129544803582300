import React from "react";
import styled from "styled-components";
import { MaxWidthContainer } from "Routes/Root/Components/Layout";

export const Hero = ({ heroContent }) => {
  return (
    heroContent && (
      <HeroWrapper id="hero">
        <HeroContainer>{heroContent}</HeroContainer>
      </HeroWrapper>
    )
  );
};

const HeroWrapper = styled.div`
  background-color: #1d1b1a;
  color: #ffffff;
  padding: 0 15px;
`;

const HeroContainer = styled(MaxWidthContainer)`
  height: 56.25vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
