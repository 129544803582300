import React from "react";
import { FieldMessage, Input } from "Components";

export const ZipCode = ({ onChange, value, field, error = {}, ...props }) => {
  const handleZip = (value) => {
    onChange({ [field.name]: value });
  };
  return (
    <>
      <Input
        onChange={handleZip}
        value={value}
        placeholder={field.placeholder || "5-digit code..."}
        type="tel"
        error={!!error.status}
        name={field.name}
        maxLength={5}
        {...props}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};
