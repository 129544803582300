import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "Components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CTASocialShareInline = () => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const shareIcons = event.footer.menus.social;

  const socialIconHandler = (link) => {
    logClick({
      label: "Social Icon",
      url: link,
    });
  };

  return (
    <ShareIcons>
      {shareIcons.map((icon, idx) => (
        <Icon
          key={idx}
          href={icon.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => socialIconHandler(icon.link)}
        >
          <FontAwesomeIcon icon={["fab", icon.icon]} />
        </Icon>
      ))}
    </ShareIcons>
  );
};

const ShareIcons = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    position: absolute;
    top: 20px;
    right: 25px;
  }
`;

const Icon = styled.a`
  display: flex;
  font-size: 30px;
  color: #ffffff;
  margin: 0 5px;

  &:hover {
    color: #031689;
  }
`;
