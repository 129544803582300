import React, { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "Components";
import { fonts } from "theme/fonts";

export const BrandAd = ({ part }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const heading = event.callToActions.brand.heading[part];
  const downloadHeading = event.callToActions.download.heading[part];
  const { buttons: downloadButtons } = event.callToActions.download;
  const { productImg } = event.callToActions.brand;

  let productImage = `${api}/o/${storageDirectory}%2F${productImg}?alt=media`;

  const adClickHandler = (link) => {
    logClick({
      label: "Brand Ad",
      url: link,
    });
  };

  return (
    <BrandCTA part={part}>
      <Copy>
        <h5>{ReactHtmlParser(heading)}</h5>
        <h6>{ReactHtmlParser(downloadHeading)}</h6>
        <DownloadButtons>
          {downloadButtons.map((badge, idx) => (
            <a
              href={badge.link}
              target="_blank"
              onClick={() => adClickHandler(badge.link)}
            >
              <img
                src={`${api}/o/${storageDirectory}%2F${badge.path}?alt=media`}
                key={idx}
              />
            </a>
          ))}
        </DownloadButtons>
      </Copy>

      {part === 0 && <ProductImg src={productImage} />}
    </BrandCTA>
  );
};

const BrandCTA = styled.div`
  background-color: #FAC42D;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #461E00;
  position: relative;
  justify-content: center;

  ${(props) =>
    props.part !== 0 &&
    `
    flex: 1;
  `}

  @media ${(props) => props.theme.maxMedia.medium} {
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
  }

  img {
    display: block;
  }
`;

const Copy = styled.div`
  text-transform: uppercase;
  text-align: center;
  line-height: 1.15;
  width: 100%;
  padding: 25px 15px;

  h5 {
    font-size: 24px;
    font-family: ${fonts.brand.condensedRegular};
    margin-bottom: 32px;

    span {
      font-family: ${fonts.brand.condensedBold};
    }
  }

  h6 {
    font-size: 18px;
    font-family: ${fonts.brand.condensedRegular};
    margin-bottom: 12px;
  }

  @media only screen and ${(props) => props.theme.maxMedia.small} {
    padding: 50px;
    align-self: center;
    max-width: 425px;
  }
`;

const DownloadButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;

  a {
    display: inline-block;
    margin-bottom: 12px;
    width: 100%;
    max-width: 160px;
  }

  img {
    width: 100%;
  }
`;

const ProductImg = styled.img`
  width: 100%;
  max-width: 350px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 50%;
    margin-top: -200px;
    max-width: 450px;
    margin-right: 50px;
  }
`;
