import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "Components";
import { fonts } from "theme/fonts";

export const HubPageCTA = () => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const { btnLink, btnText, heading } = event.callToActions.hub;

  const ctaClickHandler = () => {
    logClick({
      label: "Hub Page CTA",
      url: btnLink,
    });
  };

  return (
    <Wrapper>
      <h2>{ReactHtmlParser(heading)}</h2>
      <a
        href={btnLink}
        onClick={ctaClickHandler}
        target="_blank"
        rel="noopener noreferrer"
      >
        {ReactHtmlParser(btnText)}
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  h2 {
    color: #ffffff;
    line-height: 1;
    margin-bottom: 12px;
  }

  a {
    background-color: #fec82e;
    color: #ffffff;
    padding: 10px 35px;
    text-decoration: none;
    border-radius: 5px;
    font-family: ${fonts.header};
    font-size: clamp(18px, 3vw, 24px);
    text-transform: uppercase;
    display: inline-flex;
    letter-spacing: 2px;
  }

  a:hover,
  a:focus {
    background-color: #ebaa00;
  }
`;
