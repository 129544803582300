import React, { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Layout, MaxWidthContainer } from "../Layout";
import { EventContext, AnalyticsContext, Header } from "Components";
import { fonts } from "theme/fonts";

export const PostShow = ({ token }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  const backgroundImg = `${api}/o/${storageDirectory}%2F${event.postShow.background}?alt=media`;

  const { banner } = event.ageGate;
  const bannerOutline = `${api}/o/${storageDirectory}%2F${banner}?alt=media`;

  const { btnLink, btnTextSecondary } = event.callToActions.hub;

  const upcomingEventsClickHandler = () => {
    logClick({
      label: "Upcoming Events CTA",
      url: btnLink,
    });
  };

  return (
    <Layout flexGrow showFooter>
      <Background background={backgroundImg}>
        <Banner>
          <img src={bannerOutline} />
        </Banner>

        <Header showHeader />

        <Content>
          <ThankYouHeader>
            {token
              ? ReactHtmlParser(event?.postShow?.thankYouHeading[0])
              : ReactHtmlParser(event?.postShow?.thankYouHeading[0])}
          </ThankYouHeader>
          <ThankYouBody>
            {token
              ? ReactHtmlParser(event?.postShow?.thankYouBody[0])
              : ReactHtmlParser(event?.postShow?.thankYouBody[0])}
          </ThankYouBody>

          <a
            href={btnLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={upcomingEventsClickHandler}
          >
            {btnTextSecondary}
          </a>
        </Content>
      </Background>
    </Layout>
  );
};

const Banner = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  justify-content: center;
  top: -200px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: flex;
  }

  img {
    display: block;
    width: 33%;
    max-width: 135px;
  }
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;

  ${(props) =>
    props.background &&
    `
    background-image: url(${props.background});
    background-size: cover;
    background-position: center;
  `}
`;

const Content = styled(MaxWidthContainer)`
  padding: 25px 15px 50px;
  text-align: center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 50px 25px;
  }

  a {
    display: inline-flex;
    background-color: #fec82e;
    color: #ffffff;
    padding: 10px 35px;
    text-decoration: none;
    border-radius: 5px;
    font-family: ${fonts.header};
    font-size: clamp(18px, 3vw, 24px);
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  a:hover,
  a:focus {
    background-color: #ebaa00;
  }
`;

const ThankYouHeader = styled.h1`
  font-size: clamp(36px, 8vw, 78px);
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff;
`;

const ThankYouBody = styled.p`
  font-size: 20px;
  color: #ffffff;
  margin-top: 0;

  a {
    color: #ffffff;
  }
`;
